<template>
  <div class="PageWrapper">
    <div class="Navbar LimitedContainer">
      <Navbar />
    </div>

    <div class="Total LimitedContainer">
      <div class="TLeft">
        <div class="Header">
          <span class="TotalCompability"
            >Вам подход{{ Names.length > 1 ? "я" : "и" }}т направлени{{
              Names.length > 1 ? "я" : "е"
            }}</span
          >
          <span class="TotalCompName">{{ Names.join(",") }}</span>
        </div>

        <div class="CompInfo">
          <div
            class="CompInfoBlock"
            v-for="(cb, cbindx) in getThreeBlocksAboutFirstName"
            :key="`CompInfoBlock-${cbindx}`"
          >
            <span class="CIBTitle">{{ cb.title }}</span>
            <span class="CIBDesc" v-html="cb.desc"></span>
            <div class="CIBBG"></div>
            <div class="CIBBorder"></div>
          </div>
        </div>
      </div>

      <div class="TRight">
        <img
          src="./../../assets/img/ReDesign/pages/career_guidance/result_stock.png"
          alt=""
        />
      </div>
    </div>

    <!-- <div class="CompRating LimitedContainer">
      <span class="CRTitle">Сформировали рейтинг подходящих компетенций</span>
      <div class="CRList">
        <div
          v-for="(cc, cindx) in CompatibleCompetence"
          :key="`CC-${cindx}`"
          :class="{
            CRBlock: true,
            isHigher: isHigherResult(cc.number),
          }"
        >
          <div class="CRText" :style="`width: ${cc.number}%;`">
            <span class="CRName">{{ cc.name }}</span>
            <span class="CRCount">{{ cc.number }}%</span>
          </div>
          <div class="BGColor" :style="`width: ${cc.number}%;`"></div>
        </div>

        <div class="EndOfList">
          <span class="ELText">100%</span>
          <div class="VerticalLine"></div>
        </div>
      </div>
    </div> -->

    <div class="RecommendedJobs LimitedContainer">
      <span class="CRTitle">Рекомендуем вам посмотреть эти задачи</span>

      <div class="RJList">
        <div
          v-for="x in Jobs"
          :key="`RJCard-${x.id}`"
          class="RJCard noselect  "
        >
          <span class="RJCTitle">{{ getSectionsName(x.sections) }}</span>

          <div class="RJCIcons">
            <div class="RJCRight">
              <div class="RJCDifficult">
                <div
                  v-for="(x, xindx) in getHardLevel(x.level)"
                  :key="`filledLevel-${xindx}`"
                  class="DifficultPainted"
                ></div>
                <div
                  v-for="(x, xindx) in 3 - getHardLevel(x.level)"
                  :key="`emptyLevel-${xindx}`"
                  class="DifficultEmpty"
                ></div>
              </div>
              <span class="DifficultName">{{ x.level }}</span>
            </div>

            <div
              v-if="(x.price != null && x.price !== 0) || x.jobHire"
              class="RJCLeft"
            >
              <el-tooltip class="box-item" effect="customized">
                <template #content>
                  За выполнение данной задачи вы сможете
                  <br />получить денежное вознаграждение
                </template>
                <div
                  v-if="x.price != null && x.price !== 0"
                  class="RJCHPayment"
                ></div>
              </el-tooltip>

              <el-tooltip class="box-item" effect="customized">
                <template #content>
                  Возможность трудоустройства
                </template>
                <div v-if="x.jobHire" class="RJCHEmployment"></div>
              </el-tooltip>
            </div>
          </div>

          <span class="RJCDesc"> {{ x.name }}</span>

          <template v-if="userFollowThisTask(x.id)">
            <div class="RJCPinTaskFilled" @click="clickTaskHeart(x.id)"></div>
          </template>
          <template v-else>
            <div class="RJCPinTask" @click="clickTaskHeart(x.id)"></div>
          </template>
        </div>
      </div>

      <router-link
        :to="`/beta/start?skipRegister=true&DirectionsSelected=${NamesRaw}`"
      >
        <div class="ButtonLookAll noselect">Посмотреть все задачи</div>
      </router-link>

      <div class="Backgrounds-Collection">
        <div class="JobsBGLight"></div>
      </div>
    </div>

    <div class="OurLinks">
      <span class="CRTitle">Октагон в ВК и Telegram</span>
      <div class="OLinks">
        <a
          class="OLTelegram"
          href="https://t.me/forus_octagon"
          target="_blank"
        ></a>
        <a
          class="OLVKontakte"
          href="https://vk.com/forus.octagon"
          target="_blank"
        ></a>
      </div>
    </div>

    <div class="Footer">
      <span class="FText">Октагон 2024</span>
    </div>

    <div class="Backgrounds-Collection">
      <div class="UpperLogo"></div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarCombined.vue";

//Если нам нужно отображение результата редактора
//Работает и без первого импорта, но требует тестирования
import "@vueup/vue-quill/dist/vue-quill.core.prod.css";
import "@vueup/vue-quill/dist/vue-quill.snow.prod.css";

import {
  getAllJobsForSections,
  addFollowersJob,
  removeFollowersJob,
  getLatestFollowersJob,
} from "@/api/job.js";

import { mapGetters, mapMutations } from "vuex";

import { findById } from "@/api/user.js";

export default {
  name: "ResultCareerGuidance",
  components: {
    Navbar,
  },
  data() {
    return {
      CompInfo: [
        {
          title: "Что делает?",
          desc:
            "Создаёт и поддерживает программы на платформе «1С:Предприятие», которая автоматизирует бизнес-процессы, учёт, финансы, продажи и склад.",
        },
        {
          title: "Какая зарплата?",
          desc:
            "Средняя зарплата 1С программиста за 2023 год — 123 689 рублей (при этом она выросла по сравнению с прошлым годом).",
        },
        {
          title: "Как обучаться?",
          desc:
            "Получить компетенции 1С разработчика можно у нас на платформе Октагон, а опытные наставники помогут в этом.",
        },
      ],
      CompatibleCompetence: [
        {
          name: "1С Разработка",
          number: 58,
        },
        {
          name: "Backend",
          number: 24,
        },
        {
          name: "Frontend",
          number: 5,
        },
      ],

      Names: [],
      NamesRaw: "",
      Sectors: [],

      Jobs: [],

      ReadyList: {
        "Веб-разработка": {
          whatDoing:
            "Разрабатывает интерфейсы и внешний вид веб-сайтов и приложений, включая картинки, списки и анимацию. Также отвечает за функциональную часть: серверную разработку, настройку баз данных и создание API для взаимодействия с фронтендом.",
          payment: `Средние показатели:<br/>

    intern: 50 000–55 000 руб.<br/>
    junior: 77 000–88 000 руб.<br/>
    middle: 178 000–200 000 руб.<br/>
    senior: 336 000–342 000 руб.<br/>
    teamlead: 395 000–400 000 руб.<br/>
    *По данным сайта Хабр Карьера`,
          education: `Начать можно с вёрстки и создания сайтов, постепенно расширяя знания и переходя к более сложным проектам. Важно изучить алгоритмы программирования и структуры данных, чтобы понимать методы кодирования. Знание английского и способность к обучению также ключевые навыки. На платформе "Октагон" есть задачи по Frontend и Backend, где можно получить необходимые компетенции с помощью наставников.`,
        },
        "1С": {
          whatDoing:
            "Программист 1С адаптирует и оптимизирует системы для бизнеса, внедряя и поддерживая программное обеспечение «1С». Системный аналитик анализирует бизнес-процессы, собирает требования и формирует технические задания для разработчиков, а затем тестирует и обучает пользователей. Также выявляются потребности клиентов при внедрении продуктов.",
          payment: `Средние зарплаты по грейдам составляют: intern 42 000–64 000 руб, junior 78 000–88 000 руб, middle 137 000–167 000 руб, senior 175 000–265 000 руб, teamlead 273 000–350 000 руб. Средняя зарплата консультанта 1С в России за 2024 год составляет 93 500 рублей, с диапазоном от 47 000 до 140 000 рублей.`,
          education: `Для работы с 1С необходимо знать основы программирования и бухгалтерского учёта. Системные аналитики должны уметь описывать бизнес-процессы и работать с программными продуктами «1С». Чтобы стать специалистом 1С:УНФ, нужно пройти обучение и накопить опыт. Платформа "Октагон" предлагает задачи и наставников для всех этих направлений.`,
        },
        "ML & AI": {
          whatDoing:
            "Использует естественный язык для распознавания речи, автоматического перевода, анализа эмоциональной окраски текстов, а также для ответа на запросы. Также обучает компьютеры интерпретировать изображения, разрабатывает и обучает алгоритмы компьютерного зрения, проводит анализ данных, интегрирует и оптимизирует алгоритмы для работы в системах.",
          payment: `Зарплаты начинаются с 60 000 рублей для стажеров, а junior-специалисты могут получать от 90 000 до 125 000 рублей. Middle-разработчики зарабатывают в среднем 120 000–218 000 рублей. Senior-специалисты получают от 300 000 до 600 000 рублей, а зарплата тимлидов достигает 479 000–600 000 рублей.

*По данным сайтов Skillfactory и Хабр Карьера`,
          education: `Для работы в NLP/NLU требуются хорошие знания математики, алгоритмов и оптимизации, а также базовое представление о лингвистике, семантике и лексике. Специалист должен понимать природу данных и эффективно внедрять наработки исследователей. Для CV-инженеров важны знания математики, статистики, программирования и принципов машинного и глубокого обучения.`,
        },
        BI: {
          whatDoing: `Специалист преобразует разрозненные бизнес-данные в структурированный формат, используя специальные инструменты. Он собирает и обрабатывает информацию, создавая визуальные отчеты для аналитиков и руководителей, на основе которых принимаются стратегические и операционные решения.`,
          payment: `Средние показатели по грейдам: <br/>
- intern 77 000 руб<br/>
- junior 87 000 руб<br/>
- middle 167 000 руб<br/>
- senior 280 000 руб<br/>
- teamlead 271 000 руб<br/>
*По данным сайта Хабр Карьера`,
          education: `Специалист в области BI должен уметь работать с BI-системами, Excel, а также программировать на SQL и Python. Профессия подходит для коммуникабельных и внимательных к деталям людей, способных работать в команде. Платформа "Октагон" предлагает задачи и наставников для получения необходимых компетенций.`,
        },
        "Базы данных": {
          whatDoing:
            "Специалист в области баз данных (SQL) занимается проектированием, созданием и управлением базами данных, обеспечивая их оптимизацию и безопасность. Он разрабатывает запросы для извлечения, обновления и анализа данных, а также поддерживает работу систем, обрабатывающих большие объемы информации.",
          payment:
            "Средняя зарплата специалистов по базам данных (SQL) в России варьируется от 70 000 до 150 000 рублей в зависимости от уровня опыта и региона. Junior-специалисты могут зарабатывать около 70 000 рублей, в то время как более опытные middle и senior специалисты получают от 100 000 до 150 000 рублей.",
          education:
            "Для работы с базами данных необходимы знания SQL, понимание структуры данных и опыт работы с СУБД, такими как MySQL, PostgreSQL или MS SQL Server. Также приветствуются навыки в программировании и знание основ администрирования баз данных. Коммуникабельность и способность работать в команде являются важными качествами для взаимодействия с другими отделами.",
        },
        "Мобильная разработка": {
          whatDoing:
            "Kotlin-программист разрабатывает мобильные приложения для Android, а также десктопные и веб-приложения для iOS, Linux и Windows, включая решения для аналитики. Чаще всего они работают над проектами в веб-разработке и создании приложений для смартфонов.",
          payment:
            "Медианная зарплата Kotlin-разработчиков в первом полугодии 2024 года составляет 291 666 ₽, что на 16,3% больше, чем во втором полугодии 2023. Средние зарплаты по грейдам составляют: junior — 100 000 руб, middle — 233 333 руб, senior — 350 000 руб и teamlead — 433 333 руб. *По данным сайта Хабр",
          education: `Необходимы глубокие знания Kotlin, опыт создания и тестирования приложений для Android, а также уверенные знания технического английского и платформ разработки. На платформе "Октагон" есть задачи по направлению Kotlin, где можно получить компетенции с помощью наставников.`,
        },
        "Чат-боты": {
          whatDoing:
            "Создает инструменты для автоматизации взаимодействия с клиентами через мессенджеры и социальные сети. Эта профессия появилась на фоне роста популярности мессенджер-маркетинга.",
          payment:
            "Средняя зарплата составляет от 50 тыс. руб. и будет расти с приобретением новых знаний и опыта. В России она варьируется от 40 000 до 200 000 ₽, в Москве — от 50 000 до 200 000 ₽. *По данным сайта Профгид.",
          education: `Необходимы знания основ программирования на языках, поддерживаемых конструкторами чат-ботов, а также навыки создания REST API, интеграции с внешними системами и тестирования. Аналитические навыки и понимание поведения пользователей также важны. На платформе "Октагон" есть задачи по направлению Чат-боты, где можно получить необходимые компетенции с помощью наставников.`,
        },
        "UX/UI": {
          whatDoing:
            "Специалист в области UX/UI дизайна создает удобные и эстетически привлекательные интерфейсы для веб-сайтов и мобильных приложений. Он проводит исследования пользователей, разрабатывает прототипы и макеты, а также тестирует решения для улучшения пользовательского опыта.",
          payment:
            "Средняя зарплата UX/UI дизайнеров в России составляет от 70 000 до 150 000 рублей в зависимости от уровня опыта и региона. Junior-дизайнеры могут зарабатывать около 60 000 рублей, тогда как более опытные middle и senior специалисты получают от 100 000 до 200 000 рублей.",
          education:
            "Для работы в UX/UI необходимы знания принципов дизайна, пользовательского опыта и графических редакторов (таких как Figma или Adobe XD). Также важны навыки прототипирования и работы с отзывами пользователей. Коммуникабельность и умение работать в команде являются ключевыми качествами для успешного выполнения задач.",
        },
      },
    };
  },
  async created() {
    if (this.$route.query?.Names != null) {
      this.Names = this.$route.query?.Names.split(",").map((x) =>
        x.replaceAll("$$$", "&")
      );
      this.NamesRaw = this.$route.query?.Names;
    }
    if (this.$route.query?.SectorsIDs != null) {
      this.Sectors = this.$route.query?.SectorsIDs.split(",").map((x) =>
        parseInt(x)
      );

      let jobResponse = await getAllJobsForSections({
        sectionsIDs: this.Sectors,
      });
      this.Jobs = jobResponse.data.map((x) => {
        return { ...x, subsListModified: [] };
      });
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
    getThreeBlocksAboutFirstName() {
      let titles = [
        "Что делает?",
        "Какая зарплата?",
        "Требования к специалистам",
      ];

      if (this.Names == null || this.Names.length === 0) {
        return [
          { title: titles[0], desc: "" },
          { title: titles[1], desc: "" },
          { title: titles[2], desc: "" },
        ];
      }

      if (this.ReadyList?.[this.Names[0]] == null) {
        return [
          { title: titles[0], desc: "" },
          { title: titles[1], desc: "" },
          { title: titles[2], desc: "" },
        ];
      }

      return [
        { title: titles[0], desc: this.ReadyList[this.Names[0]].whatDoing },
        { title: titles[1], desc: this.ReadyList[this.Names[0]].payment },
        { title: titles[2], desc: this.ReadyList[this.Names[0]].education },
      ];
    },
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),

    isHigherResult(num) {
      return this.isCurrentNumberLargest(
        num,
        this.CompatibleCompetence.map((x) => x.number)
      );
    },
    isCurrentNumberLargest(currentNumber, numbersArray) {
      const maxNumber = Math.max(...numbersArray); // Находим максимальное число в массиве
      return currentNumber === maxNumber; // Сравниваем текущее число с максимальным
    },
    getSectionsName(sects) {
      return sects.map((x) => x.name).join(", ");
    },
    getHardLevel(textVal) {
      switch (textVal) {
        case "Легкий": {
          return 1;
        }
        case "Средний": {
          return 2;
        }
        case "Сложный": {
          return 3;
        }
      }
    },

    userFollowThisTask(TaskID) {
      let Task = this.Jobs.find((obj) => {
        return obj.id === TaskID;
      });

      if (Task == null) {
        return 0;
      }

      let FindFollow = Task.subsListModified.find((user) => {
        return String(user.id) === String(this.user.id);
      });

      if (FindFollow == null) {
        return false;
      } else {
        return true;
      }
    },
    async updateTaskFollowers(taskID) {
      this.TasksLoading = true;
      let subs = await this.getTaskFollowers(taskID);
      let Task = this.Jobs.find((obj) => {
        return obj.id === taskID;
      });
      Task.subsListModified = subs.data.JobFollowers;

      this.TasksLoading = false;
    },
    async clickTaskHeart(taskID) {
      if (this.userFollowThisTask(taskID)) {
        await this.unfollowTask(taskID);
      } else {
        await this.followTask(taskID);
      }

      await this.updateTaskFollowers(taskID);
    },
    async followTask(taskID) {
      let temp = [];
      temp.push(this.user.id);
      await addFollowersJob(taskID, {
        followers: temp,
      });
      findById(this.user.id).then((res) => this.SET_USER(res.data));
    },
    async unfollowTask(taskID) {
      let temp = [];
      temp.push(this.user.id);
      await removeFollowersJob(taskID, {
        followers: temp,
      });
      findById(this.user.id).then((res) => this.SET_USER(res.data));
    },
    async getTaskFollowers(taskID) {
      return await getLatestFollowersJob(taskID);
    },
  },
};
</script>

<style scoped>
.PageWrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;

  padding-top: 56px;

  width: 100%;
  min-height: 100svh;
  height: auto;

  background-color: #19173b;

  overflow: hidden;
}

.LimitedContainer {
  margin-left: auto;
  margin-right: auto;

  width: 1128px;
  height: fit-content;
}

.Navbar {
  position: relative;
  display: flex;

  margin-bottom: 36px;
}
.NavbarLogo {
  position: relative;
  display: block;

  height: 36px;
  width: fit-content;
}

.Total {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
}
.TLeft {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;

  flex-shrink: 1;
}
.TRight {
  position: relative;
  display: block;

  flex-shrink: 0;
}
.Header {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: fit-content;
  height: fit-content;
}
.TotalCompability {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #d3caffb2;
}
.TotalCompName {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #ffffffe5;
}
.CompInfo {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: fit-content;
  max-width: 100%;
}
.CompInfoBlock {
  position: relative;
  display: flex;

  padding: 12px 20px;

  flex-direction: column;
  justify-content: flex-start;
}
.CIBTitle,
.CIBDesc,
.CRTitle,
.CRName,
.CRCount,
.ELText {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  color: #ffffffe5;
  z-index: 2;
}
.CIBTitle {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
.CIBDesc {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}

.CIBBG {
  position: absolute;
  top: 0%;
  left: 0%;

  width: 100%;
  height: 100%;
  z-index: 1;

  clip-path: inset(2px round 7px);

  background-color: #19173b;
}
.CIBBorder {
  position: absolute;

  top: 50%;
  left: 50%;

  padding: 2px;

  width: 100%;
  height: 100%;

  content: "";

  translate: -50% -50%;

  border-radius: 8px;

  background: linear-gradient(
    224.99deg,
    #1e1b4a 0.05%,
    #1f1c4a 48.65%,
    #31337b 100.05%
  );

  z-index: 0;
}

.CompRating {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}
.CRTitle {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
.CRList {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.CRBlock {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  z-index: 2;
}
.CRText {
  position: relative;
  display: flex;

  padding: 12px 20px;

  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  min-width: fit-content;

  z-index: 1;
}
.CRText > * {
  margin-top: auto;
  margin-bottom: auto;
}
.CRBlock.isHigher > .BGColor {
  background: linear-gradient(225.45deg, #ec4bc9 -0.05%, #656bff 99.95%);
}
.BGColor {
  position: absolute;
  display: block;

  top: 0;
  left: 0;

  height: 100%;

  background: #27235c;

  border-radius: 12px;
  z-index: 0;
}
.CRName {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}
.CRCount {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
}
.EndOfList > * {
  margin-top: auto;
  margin-bottom: auto;
}
.EndOfList {
  position: absolute;
  display: flex;

  right: 0;
  top: 0;

  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;

  width: fit-content;
  height: 100%;

  z-index: 0;
}
.ELText {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #d3caff4d;
}
.VerticalLine {
  position: relative;
  display: block;

  width: 1.5px;
  height: 100%;

  background-color: #27235c;
}

.RecommendedJobs {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}
.RJList {
  position: relative;
  display: grid;

  width: 100%;
  height: fit-content;

  gap: 24px;
  grid-template-columns: repeat(4, 1fr);
}
.RJCard {
  position: relative;
  display: flex;

  padding: 20px;

  width: 100%;
  height: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;

  border-radius: 12px;
  transition: 0.4s;

  background-color: #1e1b4a;
}
.RJCard:hover {
  background-color: #1e1b4acc;
}
.RJCTitle {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #ffffffe5;
}
.RJCIcons > * {
  margin-top: auto;
  margin-bottom: auto;
}
.RJCIcons {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}
.RJCLeft {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}
.RJCHPayment,
.RJCHEmployment {
  position: relative;
  display: block;

  width: 36px;
  height: 36px;
}
.RJCHPayment {
  background: url("./../../assets/img/ReDesign/interface_icons/job_card_have_payment_36px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.RJCHEmployment {
  background: url("./../../assets/img/ReDesign/interface_icons/job_card_have_employment_36px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.RJCRight {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-end;
  gap: 0;
}
.RJCDifficult {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-end;
  gap: 1px;
}
.DifficultPainted {
  position: relative;
  display: block;
  width: 10px;
  height: 20px;
  background-image: url("./../../assets/img/profile/ProfileProjectCard/DifficultIcon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.DifficultEmpty {
  position: relative;
  display: block;
  width: 10px;
  height: 20px;
  background-image: url("./../../assets/img/profile/ProfileProjectCard/DifficultIconPlaceholder.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.DifficultName {
  position: relative;
  display: flex;

  margin-left: auto;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: right;
  text-transform: uppercase;

  color: #d3caffb2;
}
.RJCDesc {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #ffffffe5;
}
.RJCPinTask {
  position: relative;
  display: flex;

  margin-top: auto;

  cursor: pointer;

  width: 32px;
  height: 32px;

  background: url("./../../assets/img/ReDesign/interface_icons/heart_icon_white_16px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;

  border-radius: 100%;
  background-color: #656bff40;
}
.RJCPinTaskFilled {
  position: relative;
  display: flex;

  margin-top: auto;

  cursor: pointer;

  width: 32px;
  height: 32px;

  background: url("./../../assets/img/ReDesign/interface_icons/heart_icon_white_filled_16px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;

  border-radius: 100%;
  background-color: #b259e1;
}
.ButtonLookAll {
  position: relative;
  display: flex;

  cursor: pointer;

  padding: 12px 26px;
  margin: 0% auto;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  background-color: #656bff;
  border-radius: 4px;
}

.OurLinks {
  position: relative;
  display: flex;

  padding: 6px 0% 20px 0%;
  margin: 0% auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}
.OLinks {
  position: relative;
  display: flex;

  margin: 0% auto;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.OLTelegram,
.OLVKontakte {
  position: relative;
  display: block;

  width: 32px;
  height: 32px;
}
.OLTelegram {
  background: url("./../../assets/img/ReDesign/interface_icons/social_tg_32px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.OLVKontakte {
  background: url("./../../assets/img/ReDesign/interface_icons/social_vk_32px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Footer {
  position: relative;
  display: flex;

  padding: 26px 0%;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: space-around;

  background-color: #00000080;
}
.FText {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #ffffffe5;
}
</style>

<style scoped>
.Backgrounds-Collection {
  position: absolute;
  display: block;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 1;
}
.Backgrounds-Collection > * {
  pointer-events: none;
}
.PageWrapper > *:not(.Backgrounds-Collection),
.RecommendedJobs > *:not(.Backgrounds-Collection) {
  z-index: 2;
}

.UpperLogo {
  position: relative;
  display: block;

  margin: 0% auto;

  width: 1007px;
  height: 442px;

  background: url("./../../assets/img/ReDesign/pages/career_guidance/result_upper_logo.svg");
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: contain;
}
.JobsBGLight {
  position: absolute;
  display: block;

  left: calc(-1 * calc((100svw - 100%) / 2));
  top: -10%;

  width: 100svw;
  height: auto;

  aspect-ratio: 1 / 1;

  background: url("./../../assets/img/ReDesign/pages/career_guidance/result_jobs_bg_light.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  transform: translateY(-20%);
}
</style>

<style scoped>
@media (max-width: 480px) {
  .RJList {
    grid-template-columns: repeat(1, 1fr);
  }
  .TRight > img {
    margin: 0% auto;
    width: 100%;
    max-width: 500px;
  }

  .TotalCompability {
    font-size: 16px;
    line-height: 20px;
  }
  .TotalCompName {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .TRight > img {
    margin: 0% auto;
    width: 100%;
    max-width: 500px;
  }

  .TotalCompability {
    font-size: 16px;
    line-height: 20px;
  }
  .TotalCompName {
    font-size: 32px;
    line-height: 36px;
  }
}

@media (max-width: 1200px) {
  .Total {
    flex-direction: column-reverse;
  }
  .TRight {
    display: flex;
  }
  .TRight > img {
    margin: 0% auto;
  }

  .LimitedContainer {
    width: calc(100% - 40px);
  }
}

@media (max-width: 992px) {
  .JobsBGLight {
    opacity: 0;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .RJList {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .RJList {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
